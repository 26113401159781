import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import SlickSlider from 'react-slick'
import { getImageDataByFilename } from '../../utils/getImageDataByFilename'

const slickSettings = {
  arrows: false,
  autoplay: true,
  autoplaySpeed: 10000,
  dots: true,
  infinite: true,
  fade: true,
  speed: 250,
  slidesToShow: 1,
  slidesToScroll: 1,
}

type QuoteSliderSlideProps = {
  title: string
  subtitle: string
  image: JSX.Element
  quote: string
  author: string
  authorSubtitle?: string
}

const QuoteSliderSlide = ({
  title,
  subtitle,
  image,
  quote,
  author,
  authorSubtitle,
}: QuoteSliderSlideProps) => {
  return (
    <div>
      <div className="flex flex-row flex-wrap h-full">
        <div className="w-full h-full md:w-1/2 md:pr-10 lg:pr-25">{image}</div>
        <div className="w-full md:w-1/2">
          <p className="subtitle mt-3.5">{`// ${title}`}</p>
          <h2>{subtitle}</h2>
          <div className="pb-5 mt-5 quote md:mt-15 lg:w-120">
            <h3 className="text-2xl" data-testid="quote">
              &quot;{quote}&quot;
            </h3>
            <p className="mt-5 font-bold text-right">{author}</p>
            {authorSubtitle && (
              <p className="mt-2 text-sm text-right">{authorSubtitle}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

interface IQuoteSliderProps {
  content: {
    title: string
    subtitle: string
    quotes: {
      image: string
      quote: string
      author: string
      authorSubtitle?: string
    }[]
  }
}

const QuoteSlider = (props: IQuoteSliderProps) => {
  const { title, subtitle, quotes } = props.content
  if (quotes.length > 0 && title.length > 0 && subtitle.length > 0) {
    return (
      <div className="overflow-hidden pb-15 inner-container">
        <SlickSlider {...slickSettings}>
          {quotes.map((quote, index) => (
            <QuoteSliderSlide
              key={index}
              title={title}
              subtitle={subtitle}
              image={
                <GatsbyImage
                  image={getImageDataByFilename(quote.image)}
                  alt=""
                  className="w-full h-full object-cover"
                />
              }
              quote={quote.quote}
              author={quote.author}
              authorSubtitle={quote.authorSubtitle}
            />
          ))}
        </SlickSlider>
      </div>
    )
  } else return null
}

export default QuoteSlider
