import React from 'react'
import { Link } from 'gatsby'
import './FeaturedLink.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImageDataByFilename } from '../../utils/getImageDataByFilename'

interface IFeaturedLinkProps {
  title: string
  subtitle: string
  description: string
  link: { text: string; url: string }
  image: string
  imageLeft?: boolean
  swipeLink?: boolean
}

const FeaturedLink = (props: IFeaturedLinkProps) => {
  const {
    title,
    subtitle,
    description,
    link,
    image,
    imageLeft = false,
    swipeLink = false,
  } = props

  if (title && subtitle && description && link && image) {
    return (
      <div className="FeaturedLink" data-testid="featured-link">
        <div className="FeaturedLink__container">
          <div className="FeaturedLink__inner-container inner-container standard-page-section">
            {imageLeft && (
              <div className="FeaturedLink__image-container">
                <GatsbyImage image={getImageDataByFilename(image)} alt="" />
              </div>
            )}
            <div className="FeaturedLink__text-container">
              <div
                className={`flex flex-col justify-center h-full ${
                  imageLeft ? 'md:pl-10 lg:pl-25' : 'md:pr-10 lg:pr-25'
                }`}
              >
                <div className="flex-none">
                  <p className="mt-10 subtitle md:mt-0">{`// ${title}`}</p>
                  <h2>{subtitle}</h2>
                  <p className="main-p">{description}</p>
                  <Link
                    to={link.url}
                    className={` block ${
                      swipeLink
                        ? 'FeaturedLink__swipe-link swipe-link swipe-link-2'
                        : 'mt-8 btn btn-2 py-2 px-4'
                    }`}
                  >
                    <span data-hover={link.text}>{link.text}</span>
                  </Link>
                </div>
              </div>
            </div>
            {!imageLeft && (
              <div className="FeaturedLink__image-container">
                <GatsbyImage image={getImageDataByFilename(image)} alt="" />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  } else return null
}

export default FeaturedLink
