import * as React from 'react'
import { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import Layout from 'components/Layout'
import Modal from 'components/Modal'
import Slider from 'components/slider'
import GetStartedParallaxSection from 'components/homepage/GetStartedParallaxSection'
import Partnerships from 'components/homepage/Partnerships'
import demosArray from 'data/demos/demosArray'
import VideoHeroLocal from 'components/VideoHeroLocal/VideoHeroLocal'

import ImageVideoPlayIconSvg from 'images/videoPlayIconSvg'

import heroVideo from 'videos/x-series/aurora-trimmed.mp4'
import heroVideoThumbnail from 'videos/thumbnails/aurora-thumbnail.png'

const Homepage = () => {
  const [viewportWidth, setViewportWidth] = useState(0)

  /** Keep track of the viewport/window width */
  useEffect(() => {
    setViewportWidth(window.innerWidth)
    const handleResizeWindow = () => setViewportWidth(window.innerWidth)
    window.addEventListener('resize', handleResizeWindow)
    return () => {
      window.removeEventListener('resize', handleResizeWindow)
    }
  }, [setViewportWidth])

  const getParallaxHeight = (screenWidth: number) => {
    if (screenWidth > 400) {
      return '42rem'
    } else {
      return '45rem'
    }
  }

  return (
    <Layout>
      <>
        {/* HERO */}
        <div>
          <VideoHeroLocal
            customTextWidth="w-1/2"
            copyElement={
              <>
                <p className="text-white subtitle opacity-70">
                  {'// Quantum Hardware'}
                </p>
                <h1>
                  Introducing Aurora:{' '}
                  <span className="text-4xl block">
                    {"World's"} first modular, scalable{' '}
                    <span className="lg:block">
                      and networked quantum computer
                    </span>
                  </span>
                </h1>
                <div className="mt-5.5">
                  <div className="flex">
                    <Modal
                      interactionStyles="block"
                      interaction={
                        <div className="watch-video mb-3">
                          <ImageVideoPlayIconSvg
                            circleColor="text-white"
                            widthAndHeight={30}
                          />
                          <span className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white">
                            <span data-hover="Watch Video">Watch Video</span>
                          </span>
                        </div>
                      }
                      title="Quantum Computational Advantage with Borealis"
                      content={
                        <div className="video-wrapper">
                          <iframe
                            src="https://www.youtube.com/embed/z0hSw2BC4mI?autoplay=1"
                            title="YouTube video player"
                            style={{ border: 'none' }}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      }
                    />
                    <Link to="/blog/lighting-up-the-quantum-computing-horizon-with-aurora">
                      <span className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white">
                        <span data-hover="Read Blog">Read Blog</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </>
            }
            video={heroVideo as string}
            aspectRatio={2538 / 1080}
            thumbnail={heroVideoThumbnail as string}
          />
        </div>
        {/* Get Started */}
        <div
          className="relative"
          style={{ height: getParallaxHeight(viewportWidth) }}
        >
          <div className="absolute w-full h-full">
            <GetStartedParallaxSection coordinates={[-0, -80]} />
            <GetStartedParallaxSection coordinates={[-80, 0]} />
            <GetStartedParallaxSection coordinates={[0, -80]} />
          </div>
          <div className="absolute w-full h-full bg-[#f2f3f7] opacity-50"></div>
          <div className="absolute w-full overflow-hidden standard-page-section">
            <div className="inner-container">
              <div className="lg:w-6/12">
                <p className="subtitle">{'// Quantum Software'}</p>
                <h2>Build with PennyLane</h2>
                <p className="main-p">
                  Develop quantum applications and program quantum computers
                  with PennyLane&apos;s library of tools, demonstrations,
                  tutorials and community support forum.
                </p>
                <Link
                  to="/products/pennylane"
                  className="mt-5.5 btn btn-lg btn-1"
                >
                  Try now
                </Link>
              </div>
              <div className="mt-8.5">
                <Slider
                  width="w-1/4"
                  extraClass="slider-homepage"
                  demos={demosArray}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Catalyst */}
        <div className="standard-page-section">
          <div className="inner-container flex flex-col lg:flex-row gap-10">
            <div className="lg:w-1/2">
              <p className="subtitle">{'// Quantum Software'}</p>
              <h2>Compile with Catalyst</h2>
              <p className="main-p max-w-[560px]">
                Fully integrated with PennyLane, Catalyst enables just-in-time
                compilation of entire quantum-classical workflows. Optimize your
                programs to run meaningful quantum algorithms.
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.pennylane.ai/projects/catalyst/en/stable/"
                className="mt-5.5 btn btn-lg btn-1"
              >
                Learn more
              </a>
            </div>
            <div className="hidden lg:block lg:w-1/2">
              <StaticImage src="../../images/homepage/qjit.png" alt="" />
            </div>
          </div>
        </div>

        {/* Partnerships */}
        <div className="overflow-hidden bg-partnerships standard-page-section">
          <div className="inner-container">
            {/* text */}
            <div className="flex flex-col items-center text-center text-white">
              <p className="text-white subtitle opacity-70">
                {'// Partnerships'}
              </p>
              <h2>Collaborate with Xanadu</h2>
              <p className="main-p md:max-w-lg">
                Partnering with leading companies to break new ground in
                hardware, software and quantum applications.
              </p>
              <br />
              <div className="hidden sm:flex justify-between lg:justify-around w-full md:w-10/12 lg:w-8/12 flex-col md:flex-row h-25 md:h-4 lg:px-8 xl:px-16">
                <div>
                  <a
                    href="https://arxiv.org/abs/2204.11890"
                    target="_blank"
                    className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white"
                    rel="noopener noreferrer"
                  >
                    <span data-hover="Volkswagen: Battery Simulation">
                      Volkswagen: Battery Simulation
                    </span>
                  </a>
                </div>
                <div>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href="https://pennylane.ai/qml/demos/tutorial_tn_circuits/"
                    target="_blank"
                    className="swipe-link swipe-link-white ml-2.5 text-lg font-semibold border-b-2 border-white"
                    rel="noopener"
                  >
                    <span data-hover="BMW: Quantum Machine Learning">
                      BMW: Quantum Machine Learning
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <Partnerships />
          </div>
        </div>
        {/* Company (Careers) */}
        <div className="py-15 standard-page-section">
          <div className="inner-container">
            <div className="flex flex-col items-center text-center">
              <p className="subtitle">{'// Join Xanadu'}</p>
              <h2 className="mt-2.5">Come make a difference with us</h2>
              <p className="mt-5 md:w-128">
                Work with our team of high performing quantum experts and
                builders, leading the path towards fault tolerant quantum
                computers.
              </p>
              <div className="mt-10 -mb-5">
                <a
                  href="https://xanadu.applytojob.com/apply"
                  target="_blank"
                  className="mb-5 mx-2.5 btn btn-lg btn-1"
                  rel="noopener noreferrer"
                >
                  Open Positions
                </a>
                <Link to="/research" className="mx-2.5 btn btn-lg btn-2">
                  Research
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  )
}

export default Homepage
