import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImageDataByFilename } from '../../utils/getImageDataByFilename'

type PartnershipArrayItem = {
  title: string
  image: unknown
  link: string
  size: '140px' | '100px' | '50px'
}

const partnershipsArray: PartnershipArrayItem[] = [
  {
    title: 'Volkswagen',
    image: 'VolkswagenSVG.png',
    link: '',
    size: '140px',
  },
  {
    title: 'AWS',
    image: 'aws.png',
    link: '',
    size: '50px',
  },
  {
    title: 'imec',
    image: 'imec.png',
    link: '',
    size: '140px',
  },
  {
    title: 'GlobalFoundries',
    image: 'globalfoundries.png',
    link: '',
    size: '140px',
  },
  {
    title: 'BMW',
    image: 'BMWGroup_PNG.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Nvidia',
    image: 'Nvidia.png',
    link: '',
    size: '140px',
  },
  {
    title: 'VTT',
    image: 'vtt-logo.png',
    link: '',
    size: '50px',
  },
  {
    title: 'NIST',
    image: 'NIST_logo.png',
    link: '',
    size: '100px',
  },
  {
    title: 'ligentec',
    image: 'ligentec.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Google',
    image: 'google.png',
    link: '',
    size: '100px',
  },
  {
    title: 'IBM',
    image: 'ibm.png',
    link: '',
    size: '100px',
  },
  {
    title: 'Toyota',
    image: 'toyota.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Rolls-Royce',
    image: 'rr-logo.png',
    link: '',
    size: '50px',
  },
  {
    title: 'Los Alamos National Laboratory',
    image: 'lanl.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Oakridge National Laboratory',
    image: 'oakridge.png',
    link: '',
    size: '140px',
  },
  {
    title: 'qBraid',
    image: 'qbraid.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Entropica Labs',
    image: 'entropica-labs.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Scotiabank',
    image: 'scotiabank.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Microsoft',
    image: 'microsoft.png',
    link: '',
    size: '140px',
  },
  {
    title: 'CMC Microsystems',
    image: 'cmc.png',
    link: '',
    size: '100px',
  },
  {
    title: 'Rigetti',
    image: 'rigetti.png',
    link: '',
    size: '100px',
  },
  {
    title: 'Quantinuum',
    image: 'Quantinuum.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Creative Destruction Lab',
    image: 'creative-destruction-lab.png',
    link: '',
    size: '140px',
  },
  {
    title: 'AQT',
    image: 'aqt.png',
    link: '',
    size: '100px',
  },
  {
    title: 'Agnostiq',
    image: 'agnostiq.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Multiverse',
    image: 'multiverse.png',
    link: '',
    size: '140px',
  },
  {
    title: 'CERN',
    image: 'cern.png',
    link: '',
    size: '50px',
  },
  {
    title: 'DARPA',
    image: 'darpa.png',
    link: '',
    size: '100px',
  },
  {
    title: 'Covestro',
    image: 'covestro.png',
    link: '',
    size: '50px',
  },
  {
    title: 'Stanford',
    image: 'stanford.png',
    link: '',
    size: '50px',
  },
  {
    title: 'IonQ',
    image: 'ionq.png',
    link: '',
    size: '100px',
  },
  {
    title: 'Center for Quantum Networks',
    image: 'cqn.png',
    link: '',
    size: '50px',
  },
  {
    title: 'Harvard',
    image: 'harvard.png',
    link: '',
    size: '140px',
  },
  {
    title: 'MIT',
    image: 'mit-logo.png',
    link: '',
    size: '140px',
  },
  {
    title: 'NRC',
    image: 'nrc-logo',
    link: '',
    size: '140px',
  },
  {
    title: 'MILA',
    image: 'mila.png',
    link: '',
    size: '100px',
  },
  {
    title: 'MaRS',
    image: 'mars-logo-3.png',
    link: 'https://www.marsdd.com/',
    size: '50px',
  },
  {
    title: 'UBC',
    image: 'uBc.png',
    link: '',
    size: '50px',
  },
  {
    title: 'Quantum Algorithm Institute',
    image: 'qai-logo.png',
    link: '',
    size: '50px',
  },
  {
    title: 'Innovative Solutions Canada',
    image: 'isc.png',
    link: 'https://www.ic.gc.ca/eic/site/101.nsf/eng/home',
    size: '100px',
  },
  {
    title: 'University of Toronto',
    image: 'uoft-logo.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Institut Quantique',
    image: 'institut-quantique.png',
    link: '',
    size: '140px',
  },
  {
    title: 'Institute for Quantum Computing',
    image: 'iqc.png',
    link: '',
    size: '100px',
  },
  {
    title: 'Open Quantum Design',
    image: 'oqd-logo.png',
    link: '',
    size: '100px',
  },
]

const Partnerships = () => {
  return (
    <div className="text-white mt-15">
      {/* Desktop List */}
      <div className="flex-wrap justify-center hidden -m-6 text-white partnerships-secion md:flex">
        {partnershipsArray.map((item, index) => {
          return <PartnerImage key={`${item.title}-${index}`} item={item} />
        })}
      </div>
      {/* Mobile List */}
      <div className="flex flex-wrap justify-center -m-6 text-white partnerships-secion md:hidden">
        {partnershipsArray.slice(0, 6).map((item, index) => {
          return (
            <PartnerImage key={`mobile-${item.title}-${index}`} item={item} />
          )
        })}
      </div>
    </div>
  )
}

const PartnerImage = ({ item }: { item: PartnershipArrayItem }) => {
  return (
    <div className="p-6">
      <div style={{ width: item.size, height: '100%' }}>
        <GatsbyImage
          image={getImageDataByFilename(String(item.image))}
          alt={item.title}
          className="white-image-filter max-w-full max-h-full object-contain"
        />
      </div>
    </div>
  )
}

export default Partnerships
