import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faTwitter,
  faGithub,
  faGoogle,
} from '@fortawesome/free-brands-svg-icons'
import './Participant.css'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { getImageDataByFilename } from '../../utils/getImageDataByFilename'
import { IParticipant } from 'types'

interface IParticipantProps {
  showImage: boolean
  participant: IParticipant
}

const Participant = (props: IParticipantProps) => {
  const { showImage, participant } = props
  return (
    <div className="Participant" data-testid="participant">
      <div>
        {showImage && (
          <>
            {participant.image ? (
              <GatsbyImage
                className="rounded-sm border border-transparent"
                image={getImageDataByFilename(participant.image)}
                alt={participant.name}
              />
            ) : (
              <StaticImage
                className="rounded-sm border border-grey-700"
                src="../../images/pennylane-research-retreat/avatar.png"
                alt={participant.name}
              />
            )}
          </>
        )}
      </div>
      <h3 className={`${showImage && 'md:text-lg'} text-lg mt-2`}>
        {participant.name}
      </h3>
      <p className="text-sm mb-2">{participant.affiliation}</p>
      <ul className="flex gap-3 md:gap-6 text-grey-700">
        {participant.linkedInUrl && (
          <li>
            <a
              href={participant.linkedInUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
              className="Participant__SocialLink"
              data-testid="linkedin"
            >
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </a>
          </li>
        )}
        {participant.twitterUrl && (
          <li>
            <a
              href={participant.twitterUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
              className="Participant__SocialLink"
              data-testid="twitter"
            >
              <FontAwesomeIcon icon={faTwitter} size="lg" />
            </a>
          </li>
        )}
        {participant.githubUrl && (
          <li>
            <a
              href={participant.githubUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="GitHub"
              className="Participant__SocialLink"
              data-testid="github"
            >
              <FontAwesomeIcon icon={faGithub} size="lg" />
            </a>
          </li>
        )}
        {participant.googleScholarUrl && (
          <li>
            <a
              href={participant.googleScholarUrl}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Google Scholar"
              className="Participant__SocialLink"
              data-testid="google-scholar"
            >
              <FontAwesomeIcon icon={faGoogle} size="lg" />
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

export default Participant
